import { type LoaderFunctionArgs } from "@remix-run/node";
import {
  Outlet,
  redirect,
  useLoaderData,
  useOutletContext,
} from "@remix-run/react";
import { z } from "zod";
import {
  getJoinedOrgByUser,
  getOrganization,
  getOrgParticipation,
} from "~/models/.server/organizations";
import type { organizationType } from "~/models/schemas/organization";
import { organizationSchema } from "~/models/schemas/organization";
import { requireUserId } from "~/services/auth.server";

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
  const userId = await requireUserId(request);
  const { success, data } = z
    .object({ id: z.coerce.number() })
    .safeParse(params);

  if (!success) {
    throw new Response("Not Found", { status: 404 });
  }

  const organization = await getOrganization(data.id, userId);

  if (!organization) {
    throw new Response("Not Found", { status: 404 });
  }

  // Make sure user joined the organization
  const joined = await getOrgParticipation(userId, organization.id);
  if (!joined) {
    return redirect("/community/all?error=not-joined");
  }

  const joinedOrgs = await getJoinedOrgByUser(userId, organization.id);
  const sanitizedJoinedOrgs = joinedOrgs.map((org) => {
    return organizationSchema.parse(org);
  });

  return {
    organization: organizationSchema.parse(organization),
    joinedOrgs: sanitizedJoinedOrgs,
  };
};

export default function Index() {
  const { organization, joinedOrgs } = useLoaderData<typeof loader>();

  return <Outlet context={{ organization, joinedOrgs }} />;
}

export function useOrganization() {
  return useOutletContext<{
    organization: organizationType;
    joinedOrgs: organizationType[];
  }>();
}
